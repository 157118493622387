.inactive .combatants {
  display: none;
}
.combatants {
  display: flex;
  text-align: center;
  justify-content: center;
}
.row {
  flex: 1;
  text-shadow: 0 0 8px #000;
  margin: 0 6px;
  max-width: 140px;
}
.row.self.job-dps .data-items:before,
.row.self.job-tank .data-items:before,
.row.self.job-healer .data-items:before {
  background: rgba(255, 255, 255, 0.8);
}
.row.job-dps .data-items:before {
  background: rgba(244, 67, 54, 0.5);
}
.row.job-dps .data-items.highlight:before {
  background-image: linear-gradient(
    to left,
    rgba(244, 67, 54, 0.5) 0%,
    rgba(244, 67, 54, 0.5) 51%,
    rgba(244, 67, 54, 0.1) 51%,
    rgba(244, 67, 54, 0.1) 100%
  );
  background-repeat: no-repeat;
}
.row.job-tank .data-items:before {
  background: rgba(32, 149, 243, 0.5);
}
.row.job-tank .data-items.highlight:before {
  background-image: linear-gradient(
    to left,
    rgba(32, 149, 243, 0.5) 0%,
    rgba(32, 149, 243, 0.5) 51%,
    rgba(32, 149, 243, 0.1) 51%,
    rgba(32, 149, 243, 0.1) 100%
  );
  background-repeat: no-repeat;
}
.row.job-healer .data-items:before {
  background: rgba(139, 195, 74, 0.5);
}
.row.job-healer .data-items.highlight:before {
  background-image: linear-gradient(
    to left,
    rgba(139, 195, 74, 0.5) 0%,
    rgba(139, 195, 74, 0.5) 51%,
    rgba(139, 195, 74, 0.1) 51%,
    rgba(139, 195, 74, 0.1) 100%
  );
  background-repeat: no-repeat;
}
.row.job-healer .data-items.highlight.inverse:before {
  background-image: linear-gradient(
    to left,
    rgba(139, 195, 74, 0.1) 0%,
    rgba(139, 195, 74, 0.1) 51%,
    rgba(139, 195, 74, 0.5) 51%,
    rgba(139, 195, 74, 0.5) 100%
  );
  background-repeat: no-repeat;
}
.row.self .data-items:before,
.row.self .data-items.highlight:before,
.row.self .data-items.highlight.invert:before {
  background: rgba(255, 255, 255, 0.8);
}
.row.job-dps .damage-percent-bg {
  background: rgba(244, 67, 54, 0.3);
}
.row.job-tank .damage-percent-bg {
  background: rgba(33, 150, 243, 0.3);
}
.row.job-healer .damage-percent-bg {
  background: rgba(139, 195, 74, 0.3);
}
.row.self .damage-percent-bg {
  background: rgba(255, 255, 255, 0.5);
}
.row.job-dps .damage-percent-fg {
  background: rgba(244, 67, 54, 0.7);
}
.row.job-tank .damage-percent-fg {
  background: rgba(33, 150, 243, 0.7);
}
.row.job-healer .damage-percent-fg {
  background: rgba(139, 195, 74, 0.7);
}
.row.self .damage-percent-fg {
  background: rgba(255, 255, 255, 1);
}
/*
.row.job-dps .dps.irrelevant {
  background: rgba(244, 67, 54, 0.1);
}
.row.job-tank .dps.irrelevant {
  background: rgba(33, 150, 243, 0.1);
}
.row.job-healer .dps.irrelevant {
  background: rgba(139, 195, 74, 0.1);
}
.row.self div.dps.irrelevant {
  background: rgba(255, 255, 255, 0.5);
}
.row .dps.irrelevant {
  background: rgba(0, 0, 0, 0.1);
}
    */
.row .damage-percent-bg {
  height: 2px;
  background: rgba(0, 0, 0, 0.3);
  transform: skew(-30deg);
  position: relative;
  left: -8px;
  margin: 0;
}
.row .damage-percent-fg {
  height: 2px;
  background: rgba(0, 0, 0, 0.7);
  transform: skew(-30deg);
  margin: 0;
}
.row .damage-percent {
  position: relative;
  text-align: right;
  width: 100%;
  font-size: 7px;
  top: -2px;
  right: 10px;
}

.row .dps {
  color: #fff;
  text-shadow: 0 0 5px #000;
  padding: 0 0 2px 8px;
  margin: 3px 0 0 0;
  flex-grow: 1;
  position: relative;
  text-align: left;
}
.row.self .dps {
  color: #000;
  text-shadow: none;
}

.row .data-items:before {
  background: rgba(0, 0, 0, 0.3);
  transform: skew(-30deg);
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 96%;
}
.row > div {
  margin: 0px auto;
  padding: 0 0 1px;
}
.row div.dps > div {
  /* transform: skew(30deg); */
}

.job {
  width: 20px;
  position: absolute;
  top: -5px;
  left: 59px;
  z-index: 1;
}
.name {
  white-space: nowrap;
}
.data-items {
  display: flex;
  width: 140px;
  position: relative;
}
.dps:last-child {
  text-align: right;
  padding-right: 8px;
  padding-left: 0;
}
.dps span.label {
  font-size: 8px;
  margin: 0 0 0 1px;
}
.row .maxhit {
  font-size: 0.85em;
  margin-top: -0.3em;
  margin-left: -0.8em;
  text-align: center;
}

.row .deaths {
  font-size: 0.85em;
  margin-top: -0.6em;
  margin-left: -0.8em;
  text-align: center;
}
